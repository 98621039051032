import parse, { HTMLReactParserOptions, domToReact, Element } from "html-react-parser";
import Image from "next/legacy/image";
import Link from "next/link";
import { isRelative } from "lib/utils";

const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode instanceof Element) {
      // Remplacement des balises <img>
      if (domNode.name === "img") {
        const {
          src,
          alt,
          class: className,
          width = "100", // Valeur par défaut si non définie
          height = "100", // Valeur par défaut si non définie
        } = domNode.attribs;

        if (isRelative(src)) {
          return (
            <div className={className}>
              <Image
                src={`${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}/${src}`}
                width={parseInt(width, 10)} // Conversion explicite en number
                height={parseInt(height, 10)} // Conversion explicite en number
                alt={alt || "Image"} // Valeur par défaut si alt est manquant
                layout="intrinsic"
                objectFit="cover" // Utilisation correcte pour appliquer "cover"
              />
            </div>
          );
        }
      }

      // Remplacement des balises <a>
      if (domNode.name === "a") {
        const { href, class: className } = domNode.attribs;

        if (href && isRelative(href)) {
          return (
            <Link href={href} passHref>
              <span>{domToReact(domNode.children)}</span>
            </Link>
          );
        }
      }
    }

    return null; // Par défaut, aucun remplacement
  },
};

interface FormattedTextProps {
  text?: string;
}

export function FormattedText({ text }: FormattedTextProps) {
  if (!text) return null;

  return <>{parse(text, options)}</>;
}

export default FormattedText;
