import * as React from "react";
import { DrupalNode, DrupalTaxonomyTerm } from "next-drupal";
import Link from "next/link";
import { absoluteURL, formatDate } from "lib/utils";
import Image from "next/image";
import { FormattedText } from "./formatted-text"

interface BlockFocus2Props {
  nodes: DrupalNode[];
  seminaires: DrupalTaxonomyTerm[];
}

export function BlockFocus2({ node, seminaires, ...props }: BlockFocus2Props) {
  return (
    <article {...props} className="px-6 mt-6 relative" aria-labelledby="features-heading">
      <div className="bg-white">
        <section aria-labelledby="features-heading" className="relative">
          <div className="aspect-h-2 aspect-w-3 overflow-hidden sm:aspect-w-5 lg:aspect-none lg:absolute lg:h-full lg:w-1/2 lg:pr-4 xl:pr-16">
            {seminaires.map((seminaire) => (
              <span key={seminaire.id}>
                {seminaire.field_media_image && (
                  <Image
                    alt={seminaire.field_media_image.field_media_image.filename}
                    src={absoluteURL(seminaire.field_media_image.field_media_image.uri.url)}
                    width={500}
                    height={300}
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                    className="h-full w-full object-cover object-center"
                  />
                )}
              </span>
            ))}
          </div>

          <div className="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 sm:pb-32 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:pt-32">
            <div className="lg:col-start-2">
              {seminaires.map((seminaire) => (
                <div key={seminaire.id}>
                  <Link href={seminaire.path.alias} passHref className="hover:bg-gray-100">
                    <h2 className="font-medium text-gray-500">
                      {seminaire.name}
                    </h2>
                    <span className="mt-4 text-4xl font-bold tracking-tight text-gray-900">
                      {seminaire.name}
                    </span>
                       </Link>
                    {seminaire.description && (
<FormattedText text={seminaire.description.value} className="mt-4 text-gray-500" />

                    )}
               

                  {seminaire.field_telecharger && (
                    <Link
                      href={absoluteURL(seminaire.field_telecharger.uri.url)}
                      passHref
                      className="prose-lg inline-block align-top bg-white p-5 mt-6 w-full text-gray-600"
                    >
                      <div className="inline-block">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-8 pt-1">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                        </svg>
                      </div>
                      <div className="inline-block pl-5">
                        {seminaire.name} II - Télécharger le programme
                      </div>
                    </Link>
                  )}
                </div>
              ))}

              <div className="w-full text-center text-2xl font-semibold pt-5 pl-5">
                Prochaines séances
              </div>
              <dl className="mt-10 grid grid-cols-1 gap-x-8 gap-y-10 text-sm sm:grid-cols-2">
                {node.map((focus) => (
                  <div key={focus.id} className="hover:bg-gray-100">
                    <Link href={focus.path.alias} passHref>
                      {formatDate(focus.field_date_de_l_agenda.value)} &#124; {formatDate(focus.field_date_de_l_agenda.end_value)}
                      <dt className="font-medium pt-2 text-gray-900">{focus.title}</dt>
                      <dd className="mt-2 text-gray-600">{focus.body.summary}</dd>
                    </Link>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </section>
      </div>
    </article>
  );
}
